<template>
	<div>
		<loader v-if="loading"></loader>
		
		<video autoplay preload="auto" loop muted="muted" playsinline="playsinline" class="splvideo" v-bind:src="splashvideo"></video>
        <div class="centLogo">
            <router-link to="/liveradio" title="Radio"><img :src="logo.image">
                <div class="entersite">
                    <router-link to="/liveradio" title="Radio">Enter The Site</router-link>
                </div>
            </router-link>
        </div>
			
	</div>
</template>
<script>
	import { HTTP } from '../../_helper/http-constants.js'
	export default {
		name: "Splashvideo",
		data() {
			return {
				logo: '',
				splashvideo: null,
				loading: false
			}
		},
		methods: {
			getSplashVideo() {
				this.loading = true;
				HTTP.get('/splashvideo').then(res => {
					if (res.data.status === 200) {
						this.splashvideo = res.data.data[0].splashvideo;
                        console.log("spl",this.splashvideo);
						this.loading = false;
					}else{
						this.loading = false;
					}
                    //console.log("spl",res.data.data[0].id);
				})
				.catch(err => {
					this.loading = false;
					console.log(err)
				});
			},
            getHeaderData(){
                this.loading = true;
                HTTP.get('/cms').then(res => {
                    if(res.data.status === 200){
                        this.logo = res.data.logo_data[0];
                        
                    }else{
                        this.loading = false;
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                            icon: 'warning',
                            title: res.data.message
                        });
                    }
                }).catch(err => {
                    this.loading = false;
                    console.log(err);
                });
            }
		},
		created() {
			this.getSplashVideo();
            this.getHeaderData();
		}
	}
</script>