<template>
	<div class="home">
		<loader v-if="loading"></loader>
		
		<MusicBanner :homeSlider="home_slider"/>
		<TopSongs :topSongs="top_songs"/>
		<featuredslider :FeaturedSlider="featured_slider"/>
		<MusicCategory :musiccategory="music_category"/>
		
		
		
		
		<!--<SponsorBanner :sponsorBanner="sponsor_banner"></SponsorBanner>-->
		
	</div> 
</template>
 
<script>
	import { HTTP } from '../../_helper/http-constants.js';
	import MusicBanner from './musicbanner.vue';
	import MusicCategory from './musiccategory.vue';
	import TopSongs from './topsongs.vue';
	import featuredslider from './featuredSlider.vue';
	//import SponsorBanner from './sponsorbanner.vue';
	//import DailyShows from './dailyshows.vue';
	export default {
		name: "Home",
		components: {
			MusicBanner,
			MusicCategory,
			TopSongs,
			featuredslider,
			//SponsorBanner,
			//DailyShows
		},
		data() {
			return {
				user: JSON.parse(window.localStorage.getItem('user')),
				home_slider: null,
				featured_slider:null,
				music_category: null,
				top_songs: null,
				daily_shows: null,
				//sponsor_banner: null,
				device_type: 'web',
				token: '',
				loading: false
			}
		},
		methods: {
			appUsers(){
				this.loading = true;
				if(!this.user){
					HTTP.post('/appusers', {
						device_type: this.device_type,
						token: (this.token != null || this.token != "") ? this.token : null
					}).then(res => {
						if(res.data.status === 200){
							window.localStorage.removeItem('user');
							// set user in localStorage
							const user = {
								user_id: res.data.user_id,
								device_type: res.data.device_type,
								token: res.data.token
							};
							window.localStorage.setItem('user', JSON.stringify(user));
							this.loading = false;
							//window.location.replace('/');
							// this.loading = true;
						}else{
							this.loading = false;
							this.$swal.fire({
								toast: true,
								position: 'top-end',
								showConfirmButton: false,
								timer: 3000,
								timerProgressBar: true,
								icon: 'warning',
								title: res.data.message
							});
						}
					}).catch(err => {
						console.log(err);
						this.loading = false;
					});
				}else{
					this.getHomeData();
					this.loading = false;
				}
			},
			getHomeData(){
				this.loading = true;
				HTTP.get('/cms').then(res => {
					if(res.data.status === 200){
						this.home_slider = res.data.homeslider_data;
						this.music_category = res.data.songcategory_data;
						this.top_songs= res.data.song_data;
						this.sponsor_banner = res.data.sponsorbanner_data;
						this.featured_slider = res.data.homeslider_datatwo;
						this.loading = false;
					}else{
						this.loading = false;
						this.$swal.fire({
							toast: true,
							position: 'top-end',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
							icon: 'warning',
							title: res.data.message
						});
					}
				}).catch(err => {
					this.loading = false;
					console.log(err);
				});
			}
		},
		mounted(){
			this.user = JSON.parse(window.localStorage.getItem('user'));
			this.appUsers();
			this.getHomeData();
		}
	}
</script>