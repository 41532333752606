<template>
	<div>
	<header class="wrapper-header">
		<loader v-if="loading"></loader>
		<!-- header section -->

		<nav class="theme-dark header">
			<div class="container">
				<div class="row">
					<div class="col-lg-2 col-md-2 ">
						<div class="logo img-fluid">
							<router-link to="/" title="Home"><img :src="logo.image"></router-link>
						</div>
					</div>
					<div class="col-lg-10 col-md-10 display-center">
						<div class="nav-link header-link">
							<ul>
								<li :class="[$route.name == 'Home' ? 'active' : '']">
									<router-link to="/home"><i class="fa fa-home"></i> Home</router-link>
								</li>
								<li :class="[$route.name == 'AllMusicCategory' || $route.name == 'CategoryMusic' ? 'active' : '']">
									<router-link to="/allcategory"><i class="fa fa-music"></i> Music</router-link>
								</li>
								<li :class="[$route.name == 'LiveRadio' ? 'active' : '']">
									<router-link to="/liveradio"><i class="fa fa-microphone"></i> Radio</router-link>
								</li>
								<li :class="[$route.name == 'Events' ? 'active' : '']">
									<router-link to="/events"><i class="fa fa-eye"></i> &nbsp;Events</router-link>
								</li>
								<li :class="[$route.name == 'Program' ? 'active' : '']">
									<router-link to="/program"><i class="fa fa-eye"></i> Showtimes</router-link>
								</li>
								<li :class="[$route.name == 'LiveVideo' ? 'active' : '']">
									<router-link to="/livevideo"><i class="fa fa-video-camera"></i> Live Stream</router-link>
								</li>
								<li :class="[$route.name == 'Liveevent' ? 'active' : '']">
									<router-link to="/liveevent"><i class="fa fa-calendar-o"></i> Broadcasting Live</router-link>
								</li>
								<li :class="[$route.name == 'Playlist' || $route.name == 'PlaylistSongs' ? 'active' : '']">
									<router-link to="/playlist"><i class="fa fa-play-circle-o"></i> My Playlist</router-link>
								</li>
								<li :class="[$route.name == 'Favourites' ? 'active' : '']">
									<router-link to="/favourites"><i class="fa fa-heart"></i> My Favorites</router-link>
								</li>
								
								<li :class="[$route.name == 'ContactUs' ? 'active' : '']">
									<router-link to="/contactus"><i class="fa fa-envelope"></i> Info</router-link>
								</li>
								<li :class="[$route.name == 'search' ? 'active' : '']">
									<router-link to="/search"><i class="fa fa-search" aria-hidden="true"></i></router-link>
								</li>
							</ul>
						</div>
						<div class="wrapper-end-btn mobile-btn">
							<div class="search">
								<router-link to="/search"><span><i class="fal fa-search"></i></span></router-link>
							</div>
							<div class="mobile-icon">
								<span><i class="fas fa-bars"></i></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>
		<!-- End header section -->

		<!-- mobile siderbar -->
		<div class="mobile-menu">
			<div class="siderbar-mobile">
				<div class="close-menu">
					<i class="fal fa-times"></i>
				</div>
				<div class="mobile-link">
					<ul>
								<li :class="[$route.name == 'Home' ? 'active' : '']">
									<router-link to="/home"><i class="fa fa-home"></i> &nbsp;Home</router-link>
								</li>
								<li :class="[$route.name == 'AllMusicCategory' || $route.name == 'CategoryMusic' ? 'active' : '']">
									<router-link to="/allcategory"><i class="fa fa-music"></i> &nbsp;Music</router-link>
								</li>
								<li :class="[$route.name == 'LiveRadio' ? 'active' : '']">
									<router-link to="/liveradio"><i class="fa fa-microphone"></i> &nbsp;Radio</router-link>
								</li>
								<li :class="[$route.name == 'Events' ? 'active' : '']">
									<router-link to="/events"><i class="fa fa-eye"></i> &nbsp;Events</router-link>
								</li>
								<li :class="[$route.name == 'Program' ? 'active' : '']">
									<router-link to="/program"><i class="fa fa-eye"></i> &nbsp;Showtimes</router-link>
								</li>
								<li :class="[$route.name == 'LiveVideo' ? 'active' : '']">
									<router-link to="/livevideo"><i class="fa fa-video-camera"></i> &nbsp;Live Stream</router-link>
								</li>
								<li :class="[$route.name == 'Liveevent' ? 'active' : '']">
									<router-link to="/liveevent"><i class="fa fa-calendar-o"></i> &nbsp;Broadcasting Live</router-link>
								</li>
								<li :class="[$route.name == 'Playlist' || $route.name == 'PlaylistSongs' ? 'active' : '']">
									<router-link to="/playlist"><i class="fa fa-play-circle-o"></i> &nbsp;My Playlist</router-link>
								</li>
								<li :class="[$route.name == 'Favourites' ? 'active' : '']">
									<router-link to="/favourites"><i class="fa fa-heart"></i> &nbsp;My Favorites</router-link>
								</li>
								
								<li :class="[$route.name == 'ContactUs' ? 'active' : '']">
									<router-link to="/contactus"><i class="fa fa-envelope"></i> &nbsp;Info</router-link>
								</li>
								<li :class="[$route.name == 'search' ? 'active' : '']">
									<router-link to="/search"><i class="fa fa-search" aria-hidden="true"></i> &nbsp;Search</router-link>
								</li>
								
					</ul>
				</div>
			</div>
			<div class="overlay"></div>
		</div>
	</header>
	<div class="home top-spacing bg-grey">
		<SponsorBanner v-if="!isLiveRadioRoute" :sponsorBanner="sponsor_banner"></SponsorBanner>
	</div>	
	</div>
</template>
<script>
import { HTTP } from '../../_helper/http-constants.js';
import SponsorBanner from './../home/sponsorbanner.vue'; 
export default {
	name: 'headernav',
	props: ['shouldShow'],
	components: {
			SponsorBanner
		},
	data(){
		return {
			user: JSON.parse(window.localStorage.getItem('user')),
			logo: '',
			loading: false,
			sponsor_banner: null
		}
	},
	computed: {
		// Computed property to check if the current route is LiveRadio
		isLiveRadioRoute() {
			return this.$route.name.toLowerCase() === 'liveradio' || this.$route.name.toLowerCase() === 'program';
			
		}
	},
	methods: {
		getHeaderData(){
			this.loading = true;
			HTTP.get('/cms').then(res => {
				if(res.data.status === 200){
					this.logo = res.data.logo_data[0];
					this.sponsor_banner = res.data.sponsorbanner_data;
					this.loading = false;
				}else{
					this.loading = false;
					this.$swal.fire({
						toast: true,
						position: 'top-end',
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
						icon: 'warning',
						title: res.data.message
					});
				}
			}).catch(err => {
				this.loading = false;
				console.log(err);
			});
		}
	},
	mounted(){
		this.user = JSON.parse(window.localStorage.getItem('user'));
		this.getHeaderData();
	}
}
</script>
