<template>
    <div id="app">
        <div v-if="events.length" class="eventList">
            <!-- Loop through each event in the events array -->
            <div class="eventPart" v-for="(event, index) in events" :key="index">
                <div class="container">
                    <div class="row wrapper_flex revers_mobile">
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="eventcontent">
                                <p class="eventsub">{{ event.event_no_prefix }}{{ event.event_no }}</p>
                                <h2 class="eventTitle">{{ event.event_name }}</h2>
                                <p><font color="#ffffff" v-html="event.event_desc"></font></p>

                                <div class="bottom-icon">
                                    <ul>
                                        <li>
                                            <span class="liicon"><img src="../../../public/assets/images/Location_Icon.png" /></span>
                                            <span class="liText">Venue: @ {{ event.venue_name }}</span>
                                        </li>
                                        <li>
                                            <span class="liicon"><img src="../../../public/assets/images/global.png" /></span>
                                            <span class="liText">Where <br /> {{ event.event_location }}</span>
                                        </li>
                                        <li>
                                            <span class="liicon"><img src="../../../public/assets/images/date_icon.png" /></span>
                                            <span class="liText">When <br /> {{ formatDate(event.event_date) }}</span>
                                        </li>
                                    </ul>
                                    <div class="button-type">
                                        <button>
                                            <span class="shape">
                                                <span class="innershape"></span>
                                            </span>
                                            <span class="btnTitle"> {{ event.eventdata_1}}:</span>
                                            <span class="btnType">{{ event.eventcontain_1 }}</span>
                                        </button>

                                        <button>
                                            <span class="shape">
                                                <span class="innershape"></span>
                                            </span>
                                            <span class="btnTitle">{{ event.eventdata_2}}:</span>
                                            <span class="btnType">{{ event.eventcontain_2}}</span>
                                        </button>

                                        <button>
                                            <span class="shape">
                                                <span class="innershape"></span>
                                            </span>
                                            <span class="btnTitle">{{ event.eventdata_3}}:</span>
                                            <span class="btnType">{{ event.eventcontain_3}}</span>
                                        </button>

                                        <!-- Buy Now Button -->
                                        <button @click="openPopup(index,event.event_name)" class="buy-btn">
                                            <span class="shape">
                                                <span class="innershape"></span>
                                            </span>
                                            <span class="btnTitle buynw">{{ event.button_name }}</span>
                                        </button>
                                        <a href="javascript:void(0)" @click="fetchTermspopup" style="text-decoration:none;">
                                                <span class="liText stetx">Purchase Terms &amp; Conditions</span>
                                        </a>
                                    </div>
                                    <div class="bottom-addres">
                                        <div class="event-address">
                                            <p>{{ event.event_location }}</p>
                                            <p>{{ event.venue_name }}</p>
                                            <span>{{ event.venue_location }}</span>
                                            <p class="event-number">{{ event.event_no }}</p>
                                            <div class="eventaddressWrapper">
                                                <a :href="event.venue_googlemap" target="_blank" class="viewstyle">VIEW MAP LOCATION</a>
                                                <span class="whitelocation"><img src="../../../public/assets/images/White_Location_Icon.png"></span>
                                            </div>
                                        </div>
                                        <div class="watchplay">
                                            <div class="playicon">
                                                <p>Watch Promo Video
                                                </p>
                                                <span><a :href="event.video_url" target="_blank"><img src="../../../public/assets/images/playicon.png"></a></span>
                                            </div>
                                        </div>

                                    </div>
                                    
                                </div>
                            </div>
                            <div class="socialmedia">
                                <ul class="social-icons">
                                    <li>
                                        <a :href="event.facebook_link" target="_blank" aria-label="Facebook">
                                            <i class="fa-brands fa-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a :href="event.twitter_link" target="_blank" aria-label="Twitter">
                                            <i class="fa-brands fa-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a :href="event.linkdin_link" target="_blank" aria-label="LinkedIn">
                                            <i class="fa-brands fa-linkedin-in"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a :href="event.youtube_link" target="_blank" aria-label="YouTube">
                                            <i class="fa-brands fa-youtube"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a :href="event.instagram_link" target="_blank" aria-label="Instagram">
                                            <i class="fa-brands fa-instagram"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <!-- Image Slider -->
                            <div class="eventImage" @touchstart="startTouch" @touchmove="moveTouch" @touchend="endTouch">
                                <div class="slider">
                                    <div class="slides" :style="{ transform: `translateX(-${event.currentSlide * 100}%)` }">
                                        <img v-for="(image, imgIndex) in event.slider_images" :key="imgIndex" :src="image" />
                                    </div>
                                    <button @click="prevSlide(index)" class="prev">❮</button>
                                    <button @click="nextSlide(index)" class="next">❯</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Popup Modal for Table Selection -->
        <div v-if="isPopupOpen" class="popup-modal">
            <div class="popup-content">
                <h3>Select a Table</h3>
                <div class="table-options">
                    <button 
                        v-for="(table, tableIndex) in availableTables" 
                        :key="tableIndex" 
                        @click="redirectTo(table.button_link)" 
                        class="table-btn">
                        {{ table.table_name }} - {{ table.table_price }}
                    </button>
                </div>
                <button @click="closePopup" class="close-popup">Close</button>
            </div>
        </div>

        <div v-else>
            
        </div>
        <!-- Popup Modal for Terms and Conditions -->
        <div v-if="isTermsPopupOpen" class="popup-modal">
            <div class="popup-content">
                <h3>{{ termsTitle }}</h3>
                <div v-html="termsDescription"></div> <!-- Use v-html to render HTML content -->
                <button @click="isTermsPopupOpen = false" class="close-popup">Close</button>
            </div>
        </div>
    </div>
</template>

<script>
    import "../../../public/assets/css/event.css";
    export default {
      data() {
        return {
          events: [], // Array to store multiple events
          currentSlide: 0,
          isPopupOpen: false,
          touchStartX: 0,
          touchEndX: 0,
          selectedEvent: null, // To store the event selected for the popup
          event_name:null,
          termsTitle: '', // Title for the terms popup
            termsDescription: '', // Description for the terms popup
            isTermsPopupOpen: false, // Flag to control terms popup visibility
        };
      },
      mounted() {
        this.fetchEventData();
        this.fetchTerms();
      },
      methods: {
        // Fetch Event Data from the API
        fetchEventData() {
          const apiUrl = "https://lamegaprovidence.com/lamega/api/events";
          fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
              if (data.status === 200) {
                // this.events = data.data; // Store all events
                this.events = data.data.map(event => ({ ...event, currentSlide: 0 }));
              } else {
                console.error("Error fetching data:", data.message);
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        },
        fetchTerms() {
            fetch('https://lamegaprovidence.com/lamega/api/terms')
                .then(response => response.json())
                .then(data => {
                    if (data.status === 200) {
                        const term = data.data[0]; // Get the first term object
                        this.termsTitle = term.title;
                        this.termsDescription = term.description;
                        //this.isTermsPopupOpen = true; // Show the terms popup
                    } else {
                        console.error('Error fetching terms:', data.message);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        },
        formatDate(dateString) {
          const options = { year: "numeric", month: "long", day: "numeric", hour: "2-digit", minute: "2-digit" };
          return new Date(dateString).toLocaleDateString(undefined, options);
        },
        nextSlide(index) {
            const event = this.events[index];
            // console.log("Next slide for event:", index, "Current slide:", event.currentSlide);
            
            if (event.slider_images.length > 0) {
            // Update the currentSlide value for the selected event
            event.currentSlide = (event.currentSlide + 1) % event.slider_images.length;
            // console.log("Updated slide:", event.currentSlide);
            }
        },
        prevSlide(index) {
            const event = this.events[index];
            // console.log("Previous slide for event:", index, "Current slide:", event.currentSlide);
            
            if (event.slider_images.length > 0) {
            // Calculate the previous slide index and handle wrapping
            event.currentSlide = (event.currentSlide - 1 + event.slider_images.length) % event.slider_images.length;
            // console.log("Updated slide:", event.currentSlide);
            }
        },
        fetchTermspopup(){
            this.isTermsPopupOpen = true; 
        },
        startTouch(event) {
            this.touchStartX = event.touches[0].clientX; // Store the starting X position
        },
        moveTouch(event) {
            this.touchEndX = event.touches[0].clientX; // Update the ending X position
        },
        endTouch(index) {
            if (this.touchStartX > this.touchEndX + 50) {
                // Swipe left
                this.nextSlide(index);
            } else if (this.touchStartX < this.touchEndX - 50) {
                // Swipe right
                this.prevSlide(index);
            }
        },
        openPopup(index,event_name) {
          this.selectedEvent = this.events[index]; // Set the selected event for the popup
          this.isPopupOpen = true;
          this.event_name = event_name;
        },
        closePopup() {
          this.isPopupOpen = false;
        },
        redirectTo(link) {
            window.open(link, '_blank');
        }
      },
      computed: {
        availableTables() {
        // Return only the tables where 'available' is 1
        return this.selectedEvent?.tables_list.filter(table => table.available === 1) || [];
        }
      }
    };
</script>
