<template>
	<!-- banner slider secction -->
	<section class="banner-slider music-section">
        
        <carousel :per-page="1" :mouse-drag="true" :loop="true" :autoplay="true" :autoplayTimeout="5000" :speed="500" :navigationEnabled="true">
        <slide v-for="slider in FeaturedSlider" :key="slider.homeslider_id">
            <a :href="slider.link"><img :src="slider.image" @error="$event.target.src='assets/images/placeholder.png'"></a>
        </slide>
        </carousel>
        
	</section>
	<!-- end banner slider section -->
</template>
<script>
	export default {
		name: "featuredslider",
		props: ['FeaturedSlider']
	}
</script>