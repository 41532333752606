import * as firebase from "firebase";
import "firebase/database";

let config = {
    apiKey: "AIzaSyBZxLg8d0JJzeyv0dglKag-P0okgNOjxjg",
    authDomain: "la-mega-aaecc.firebaseapp.com",
    databaseURL: "https://la-mega-aaecc.firebaseio.com",
    projectId: "la-mega-aaecc",
    storageBucket: "la-mega-aaecc.appspot.com",
    messagingSenderId: "612647569566",
    appId: "1:612647569566:web:ecccf4b9bbb88cbfd7538f"
};

firebase.initializeApp(config);

export default firebase.database();